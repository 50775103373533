<template>
  <v-layout class="bg-purple-4">
    <v-app-bar elevation="0" color="transparent" height="100">
      <v-img src="@/assets/img/yetipay-logo-white.svg" alt="yetipay" height="48px" />
    </v-app-bar>

    <v-main class="fill-height text-white d-flex justify-center align-center pb-15">
      <v-sheet max-width="500" color="transparent" class="px-10">
        <div class="text-center text-h5 font-weight-bold my-10">Welcome to Pay-by-link</div>
        <router-view />
      </v-sheet>
    </v-main>
  </v-layout>
</template>
