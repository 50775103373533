import '@mdi/font/css/materialdesignicons.css';
import '@/assets/css/variables.scss';

import { createVuetify } from 'vuetify';

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#3C038C',
          secondary: '#E34696',
          'purple-4': '#6516DF',
        },
      },
    },
  },
});
