import App from './App.vue';

import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import { registerPlugins } from '@/plugins';

import '@/assets/css/variables.scss';

const app = createApp(App);

registerPlugins(app);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration({}), Sentry.replayIntegration()],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', /^https:\/\/basecamp\.yetipay\.me/, /^https:\/\/basecamp\.dev01\.yeti\.host/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount('#app');
